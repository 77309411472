import React, { Component } from 'react';
import StoryblokReact from 'storyblok-react';
import StoryblokClient from 'storyblok-js-client';
import { getComponent, blokToComponent } from '../components';
import {
  DomService,
  EditorService,
  LanguageService,
  NavigationService,
  StoryblokService,

} from '../services';
import { EntryData, StoryDataFromGraphQLQuery } from '../templates/types';
import { SEO } from '../components/custom/seo';
import { FontOverride } from '../components/custom/font-override';
import { HelmetHead } from '../components/custom/head';

type StoryblokEntryProps = Record<string, unknown>;
type StoryblokEntryState = EntryData;

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;
const Navigation = getComponent('navigation') as React.ElementType;
const NavigationMobile = getComponent('navigation-mobile') as React.ElementType;
const Breadcrumbs = getComponent('roche-breadcrumbs') as React.ElementType;

// eslint-disable-next-line import/no-default-export
export default class StoryblokEntry extends Component<StoryblokEntryProps, StoryblokEntryState> {
  private storyblokClient: StoryblokClient;

  private storyblokBridge: StoryblokBridge;

  private url: URL;

  public constructor(props: StoryblokEntryProps) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleStoryblokLoad = this.handleStoryblokLoad.bind(this);
    this.loadStory = this.loadStory.bind(this);
    this.setState = this.setState.bind(this);

    this.state = {} as EntryData;
  }

  /*
   * While editing, mismatches between the "real" DOM and React's virtual DOM might happen.
   * When errors like these occur React DOM unmounts, resulting in a blank page.
   *
   * Slider children, are especially prone to this exception upon deletion.
   * This is caused by the 'input' event handling.
   * To minimise editor frustration we handle these using error boundaries.
   *
   * More info:
   * https://reactjs.org/docs/reconciliation.html
   * https://reactjs.org/docs/error-boundaries.html
   */
  static getDerivedStateFromError(): { hasError: true } {
    return { hasError: true };
  }

  public async componentDidMount(): Promise<void> {
    this.storyblokClient = new StoryblokClient({
      accessToken: StoryblokService.getConfig().options.accessToken as string,
    });

    EditorService.loadStoryblokBridge(this.handleStoryblokLoad);
    window.addEventListener('rocheLoginComplete', this.handleLogin);
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong!</h1>
          <p>Your work is not lost, but you will not see any new changes until you save.</p>
          <button
            onClick={(): void => window.location.reload()}
          >
            Refresh editor view and hide this error.
          </button>
        </div>
      );
    }

    const {
      breadcrumbs,
      defaultLanguageHome,
      emergencyBanner,
      footer,
      home,
      navigation,
      onClickNotice,
      story,
    } = this.state;

    if (!story?.content) {
      return <div></div>;
    }

    const canShowNavigation = !story.content.hide_navigation && navigation;
    const shouldFixHeader = story.content.fixed_header;
    const canShowFooter = !story.content.hide_footer;
    const canShowStockPrice = !story.content.hide_stock_price;
    const isStoryTypeContent = story.content.component === 'story';
    const canShowBreadcrumbs = !story.content.hide_breadcrumbs && !isStoryTypeContent;
    const displayMicrositeHeader = story.content.microsite_header;
    const canShowMicrositeHeaderTitle = story.content.microsite_title?.[0];
    const shouldRemoveHeader = story.content.remove_header;
    const searchLink = navigation?.content?.global_search_link;

    return (
      // @ts-expect-error StoryblokReact type has no children in props but renders them properly
      <StoryblokReact content={story.content}>
        <FontOverride overrides={this.state.fontOverwrites} />
        <SEO lang={story.lang}></SEO>
        <HelmetHead lang={story.lang} />
        <RocheGlobalConfig
          {...DomService.getGlobalConfig(story, defaultLanguageHome)}
          tagPageUrl={`${window.location.origin}/${this.state.tagPageUrl}`}
        />
        <roche-animation-manager />

        <roche-offcanvas-panel id="roche-offcanvas-menu">
          <NavigationMobile
            tree={[
              (navigation?.content?.primary_navigation || []),
              (navigation?.content?.secondary_navigation || []),
            ]}
            worldWideLink={navigation?.content?.roche_world_wide_link}
          />
        </roche-offcanvas-panel>

        {!shouldRemoveHeader
          && <roche-header
            home-url={LanguageService.getHomePageUrl(home)}
            is-microsite={displayMicrositeHeader}
            fixed={shouldFixHeader}
            global-search-link={NavigationService.getParsedLink(searchLink)
              ? NavigationService.getParsedNavigationLink(searchLink)
              : null}
          >
            {canShowMicrositeHeaderTitle
              && blokToComponent({ blok: canShowMicrositeHeaderTitle, slot: 'microsite-title', getComponent })
            }
            {canShowNavigation && <Navigation tree={navigation?.content?.primary_navigation} slot="primary"></Navigation>}
            {canShowNavigation
              && <Navigation
                tree={navigation?.content?.secondary_navigation}
                slot="secondary"
                worldWideLink={navigation?.content?.roche_world_wide_link}
                globalSearchLink={NavigationService.getParsedLink(searchLink) ? searchLink : null}
                alternates={this.state.alternates}
              ></Navigation>}
          </roche-header>
        }
        {canShowBreadcrumbs && !displayMicrositeHeader && <Breadcrumbs list={breadcrumbs || []} />}
        {blokToComponent({ blok: story.content, getComponent, storyId: story.id })}
        {canShowFooter && footer
          && blokToComponent({ blok: { ...footer?.content, canShowStockPrice }, getComponent })}
        {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
        {emergencyBanner && blokToComponent({ blok: emergencyBanner.content, getComponent })}
      </StoryblokReact>
    );
  }

  private async handleStoryblokLoad(): Promise<void> {
    this.loadStory();

    const StoryblokBrigde = StoryblokService.getObject();
    if (!StoryblokBrigde) {
      return;
    }

    // @ts-expect-error StoryblokBridge has no construct signatures because it is just an interface
    this.storyblokBridge = new StoryblokBrigde({
      resolveRelations: StoryblokService.getConfig().options?.resolveRelations?.split(',') || [],
    });

    this.storyblokBridge.on(['change', 'published'], () => window.location.reload());
    this.storyblokBridge.on('input', ({ story }) => {
      const activeLanguage = LanguageService.getActiveLanguage();
      const updatedStory = { ...story, lang: activeLanguage };
      const globalConfig = DomService.getGlobalConfig(updatedStory, this.state.defaultLanguageHome);

      this.setState({ story: updatedStory, ...globalConfig });
    });

    this.storyblokBridge.pingEditor(() => (
      this.storyblokBridge.isInEditor()
        ? this.storyblokBridge.enterEditmode()
        : DomService.activateConsentScript()
    ));
  }

  private getStoryIdentifier(): string {
    // ID, which is the prefered method by storyblok
    const storyId = this.url.searchParams.get('_storyblok');
    // path url param, as fallback
    const path = this.url.searchParams.get('path');

    return storyId || path;
  }

  private async loadStory(): Promise<void> {
    this.url = new URL(window.location.href);

    const queryParams = {
      version: 'draft',
      resolve_relations: StoryblokService.getConfig().options?.resolveRelations?.split(',') || [],
      resolve_links: StoryblokService.getConfig().options?.resolveLinks || '',
    } as Record<string, unknown>;

    const { data } = await this.storyblokClient.getStory(this.getStoryIdentifier(), queryParams);
    const lang = LanguageService.getActiveLanguage();

    const story = {
      ...data.story,
      // inject lang from path, just like during create page
      lang,
      // inject allSpaceLocales, just like during create page
      allSpaceLocales: [lang],
    } as StoryDataFromGraphQLQuery;

    this.setState({
      story,
      ...DomService
        .getGlobalConfig(story, this.state.defaultLanguageHome),
    });

    await EditorService.loadGlobalComponents(
      this.storyblokClient,
      (...args) => this.setState(
        ...args,
        () => {
          const {
            content: {
              emergency_banner: emergencyBannerUuid,
              footer: footerUuid,
              home: homeUuid,
              navigation: navigationUuid,
              on_click_notice: onClickNoticeUuid,
              search: searchUuid,
            },
          } = this.state.globalComponents;

          EditorService.loadDefaultLanguageHome(homeUuid, 'defaultLanguageHome', this.storyblokClient, this.setState, queryParams);

          [
            [emergencyBannerUuid, 'emergencyBanner'],
            [footerUuid, 'footer'],
            [homeUuid, 'home'],
            [navigationUuid, 'navigation'],
            [onClickNoticeUuid, 'onClickNotice'],
            [searchUuid, 'search'],
          ]
            .forEach(([uuid, stateKeyName]) => EditorService.loadGlobalComponent(
              uuid,
              stateKeyName,
              this.storyblokClient,
              this.setState,
              queryParams,
              lang,
            ));
        },
      ),
    );
    EditorService.loadBreadcrumbs(story.full_slug, this.setState);
    const fontOverwrites = await EditorService.loadFontOverwrites(this.storyblokClient);

    this.setState({
      ...this.state, fontOverwrites,
    });
  }

  private handleLogin(): void {
    StoryblokService.redirect(({ data: { story } }) => {
      this.setState({
        story,
        ...DomService
          .getGlobalConfig(story, this.state.defaultLanguageHome),
      });
    });
  }
}
